// src/App.js
import React, { useState } from 'react';
import './App.css';
import PDFDropzone from './components/PDFDropzone';
import { extractCommentsFromPDF } from './utils/pdfParser';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import logo from './cpcs_logo.svg';
import cpcslabsLogo from './cpcs-labs-abtract1.png';

import * as xlsx from 'xlsx';

import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css'

function App() {
  const [comments, setComments] = useState([]);
  const [file, setFile] = useState();

  const handleFileDrop = async (file) => {
    setFile(file);
    const extractedComments = await extractCommentsFromPDF(file);
    setComments(extractedComments);
  };

  let dt = null;

  const exportXLSX = () => {
    console.log(dt.getTable())
    // console.log(dt.getElement())
    const workSheet = xlsx.utils.table_to_sheet(dt.getTable());
    const workBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workBook, workSheet, 'Comments');
    xlsx.writeFileXLSX(workBook, 'comments.xlsx');
  }

  return (
    <div>
      <div className='sidebar-area'>
        <div className="sidebar-title">
          <a className="cpcs-icon" href='https://cpcs.ca'><img src={logo} alt='cpcs-logo'></img></a>
          
          <h1 className='title'>PDF Comment Extractor</h1>
          <p className='description'>This tool creates a comment matrix for PDF documents, which can be downloaded in Excel format. For support, please contact a member of the IAU team.</p>
        </div>
        <div className="file-info">
          {file && (
            <div className='grid'>
              <div className='col-12 h-3rem'>
                <p>File: {file.name}</p>
              </div>
              <div className='col-12 h-3rem'>
                <p>Size: {(file.size / 1024).toFixed(2)} KB</p>
              </div>
            </div>
          )}
        </div>
        {file && (
        <div className='button-group flex align-items-center flex-wrap'>
          {/* <div className='grid'>
            <div className='col'>
              <Button className="export-button button-red" type="button" icon="pi pi-file" rounded onClick={() => dt.exportCSV()} data-pr-tooltip="CSV"></Button>
            </div>
            <div className='col'>
              <p>Export to CSV</p>
            </div>
          </div> */}
          {/* <div className='grid'>
            <div className='col-2'>
              <Button className="export-button button-blue" type="button" icon="pi pi-file-excel" rounded onClick={() => exportXLSX()} data-pr-tooltip="CSV"></Button>
              <div className='col-8'>Export to Excel</div>
            </div>
          </div> */}
          <Button className="export-button button-blue" type="button" icon="pi pi-file-excel" rounded onClick={() => exportXLSX()} data-pr-tooltip="XLSX"></Button>
          <div className='col-8'>Export to XLSX</div>
        </div>)
        }
        <div className='cpcslabs-logo'>
          <img src={cpcslabsLogo} alt="CPCS labs Logo"></img>
        </div>
        <div id="footer" className="center">
            <p>Version 0.1 | Developed by <a href="https://cpcslabs.ca" target="_blank" rel="noreferrer">Infrastructure Analytics Unit</a></p>
        </div>
      </div>
      <div className='table-area'>
        <div className="dropzone"><PDFDropzone onFileDrop={handleFileDrop} /></div>
        
        <div className="comment-matrix">
          {comments.length > 0 ? (
            // <ul>
            //   {comments.map((comment, index) => (
            //     <li key={index}>
            //       <strong>{index}:</strong> {comment.content} {comment.author} Page - {comment.page}
            //     </li>
            //   ))}
            // </ul>
            <div>
              {/* <div className='button-group'>
                <Button className="export-button button-red" type="button" icon="pi pi-file" rounded onClick={() => dt.exportCSV()} data-pr-tooltip="CSV">CSV</Button>
                <Button className="export-button button-blue" type="button" icon="pi pi-file-excel" rounded onClick={() => exportXLSX()} data-pr-tooltip="CSV">XLSX</Button>
              </div> */}
              <DataTable ref={(el) => (dt = el)} value={comments} paginator rows={10} tableStyle={{ minWidth: '50rem' }}>
                <Column field="index" header="Index" sortable></Column>
                <Column field="author" header="Author" sortable filter></Column>
                <Column field="content" header="Comment" sortable filter></Column>
                <Column field="page" header="Page" sortable filter></Column>
                <Column field="modificationDate" dataType="date" sortable filter header="Date"></Column>
                <Column field="replyto" filter sortable header="Reply To"></Column>
            </DataTable>
            </div>
          ) : (
            <p>No comments found</p>
          )}
        </div>
        {/* <div id="footer" className="center">
          <p>CPCS Easy PDF Comment Extractor | Version 0.1 | Developed by <a href="https://cpcslabs.ca" target="_blank">Infrastructure Analytics Unit</a></p>
        </div> */}
      </div>
    </div>
    
  );
}

export default App;
